<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card shadow="always">
      <div slot="header" class="clearfix">
        <span class="title">查詢條件</span>
      </div>

      <!-- 查詢條件 -->
      <el-form
        ref="form"
        :model="queryData"
        label-width="80px"
        label-position="left"
      >
        
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="功能群組">
              <el-select
                v-model="queryData.serviceGroupTeamIds"
                multiple
                placeholder="請選擇"
              >
               <el-option
                  v-for="item in serviceGroupTeamList"
                  :key="item.serviceGroupTeamId"
                  :label="item.name"
                  :value="item.serviceGroupTeamId"
                >
                </el-option>
              
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col :sm="23" class="btn-col">
            <el-button type="primary" @click="query">搜尋</el-button>
            <el-button plain @click="restoreDefault">清除</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <br />

    <el-card>
      <div slot="header" class="clearfix" style="line-height: 40.5px">
        <span class="title">查詢結果</span>
        <el-button style="float: right" type="primary" @click="add"
          >新增</el-button
        >
      </div>

      <el-table
        :data="tableData"
        border
        stripe
        empty-text="暫無數據"
        style="width: 100%"
      >
        <!-- 姓名 -->
        <el-table-column
          fixed
          prop="name"
          label="姓名"
          min-width="150"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <!-- 電子信箱 -->
        <el-table-column
          prop="email"
          label="電子信箱"
          width="220"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <!-- 帳號狀態 -->
        <el-table-column
          prop="status"
          label="帳號狀態"
          width="120"
          align="center"
        >
            <template slot-scope="scope">
                <span v-show="scope.row.status == 'CREATED'" class="span-secondary"
                >建立</span
                >
                <span v-show="scope.row.status == 'ENABLED'" class="span-success"
                >啟用</span
                >
                <span v-show="scope.row.status == 'HOLD'" class="span-warning"
                >暫停</span
                >
                <span v-show="scope.row.status == 'DISABLED'" class="span-danger"
                >停用</span
                >
                <span v-show="scope.row.status == null" class="span-danger"
                >未設定</span
                >
            </template>
        </el-table-column>

        <!-- 功能群組 -->
        <el-table-column
          label="功能群組"
          min-width="150"
          header-align="center"
          align="center"
        >
             <template slot-scope="scope">
                <div
                    v-for="(item, index) in scope.row.serviceGroupTeams"
                    :key="item.serviceGroupTeamId"
                    >
                    <div :key="index" style="padding-top: 2px; padding-bottom: 2px;">
                        <el-tag type="info">{{ item.name }}</el-tag>
                    </div>
                </div>
          </template>
        </el-table-column>

        <!-- 建立日期 -->
        <el-table-column
          prop="dateCreatedStr"
          label="建立日期"
          min-width="200"
          header-align="center"
          align="center"
        >
        </el-table-column>
        
        <!-- 驗證狀態 -->
        <el-table-column
          prop="emailVerified"
          label="驗證狀態"
          min-width="120"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span v-show="scope.row.emailVerified" class="span-success"
              >已驗證</span
            >
            <span v-show="!scope.row.emailVerified" class="span-danger"
              >未驗證</span
            >
          </template>
        </el-table-column>

        <!-- 最後修改密碼日期 -->
        <el-table-column
          prop="lastPasswordChangeDateStr"
          label="最後修改密碼日期"
          min-width="200"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <!-- 最後登入日期 -->
        <el-table-column
          prop="lastLoginDateStr"
          label="最後登入日期"
          min-width="200"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <!-- 操作 -->
        <el-table-column label="操作" width="290" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              v-on:click="edit(scope.row.id)"
              :disabled="scope.row.status == 'DISABLED'"
              >編輯權限</el-button
            >

            <el-button
              type="danger"
              size="small"
              v-on:click="accountSpecialStatusProcessing(scope.row, 'DISABLED')"
              :disabled="scope.row.status == 'DISABLED'"
              >停用</el-button
            >
       
          </template>
        </el-table-column>
      </el-table>

      <br />

    </el-card>

    
    <!--編輯_帳號-->
    <el-dialog
      :visible.sync="dialogUse.editVisible"
      width="40%"
      top="20px"
      :close-on-click-modal="false"
      @closed="closeDialog('edit')"
    >
      <AccountEdit
        :clickTime="dialogUse.clickTimeEdit"
        :id="dialogUse.editId"
        @update-finish="updateFinish"
      />
    </el-dialog>

    <!--新增_帳號-->
    <el-dialog
      :visible.sync="dialogUse.addVisible"
      width="40%"
      top="20px"
      :close-on-click-modal="false"
      @closed="closeDialog('add')"
    >
      <AccountAdd
        :clickTime="dialogUse.clickTimeAdd"
        :id="null"
        @add-finish="addFinish"
      />
    </el-dialog>

   
  </div>
</template>

<script>
import API from "../../api"
import { Message } from "element-ui"
import AccountEdit from "./AccountEdit"
import AccountAdd from "./AccountAdd"

export default {
  name: "AccountManage",
  components: { AccountEdit, AccountAdd },

  data() {
    return {
      queryData: {
        serviceGroupTeamIds: [],
      },
      serviceGroupTeamList: [],
      tableData: [],
      
      isLoading: false,
      
      dialogUse: {
        editVisible: false,
        editId: "",
        clickTimeEdit: null,
        addVisible: false,
        addId: "",
        clickTimeAdd: null,
        
      },
    }
  },
 

  created() {
     // 參考網址：https://ithelp.ithome.com.tw/articles/10204718 => Promise.all 方法
    Promise.all([this.getAllServiceGroupTeam()]).then((res) => {
      if (res[0]) {
        this.query()
      }
    })
  },

  methods: {
    //取得 全部群組功能 列表
    getAllServiceGroupTeam() {
      return new Promise((resolve, reject) => {
        this.isLoading = true
        API.groupTeam
          .getAllServiceGroupTeam()
          .then((res) => {
            // 結果
            this.serviceGroupTeamList = res.data

            this.isLoading = false
            resolve(true)
          })
          .catch((e) => {
            console.log(`getAllServiceGroupTeam getList error`)
            Message({
              message: "取值失敗!",
              type: "error",
              duration: 3 * 1000,
            })
            this.isLoading = false
            reject(false)
          })
      })
    },
    //查詢條件 恢復預設值 
    restoreDefault() {
      this.queryData.serviceGroupTeamIds = []
    },

    //帳號查詢
    query() {
      this.isLoading = true
      API.account
        .query(this.queryData)
        .then((res) => {
          this.tableData = res.data.results
          this.isLoading = false
        })
        .catch((e) => {
          console.log(`account query error`)
          Message({
            message: "查詢失敗!",
            type: "error",
            duration: 3 * 1000,
          })
          this.isLoading = false
        })
    },

    //新增帳號
    add() {
      this.dialogUse.addVisible = true
      this.dialogUse.clickTimeAdd = new Date()
    },

    //編輯帳號
    edit(id) {
      this.dialogUse.editId = id
      this.dialogUse.editVisible = true
      this.dialogUse.clickTimeEdit = new Date()
    },


    //新增帳號完成
    addFinish(newData) {
      this.query()
      this.closeDialog("add")
    },

     //編輯帳號完成
      updateFinish(newData) {
       if (newData == null) return
      let i = this.tableData.findIndex((item) => {
        return item.id == newData.id
      })
      if (i >= 0) {
        this.tableData.splice(i, 1, newData)
      }
      this.closeDialog("edit")
    },

    //關閉彈窗(新增/編輯)
    closeDialog(type) {
      switch (type) {
        case "edit":
          this.dialogUse.editVisible = false
          this.dialogUse.editId = null
          break
        case "add":
          this.dialogUse.addVisible = false
          break
  
      }
    },

     //帳號狀態改變
     setStatus(data, status) {
      this.isLoading = true
      let params = {
        status: `${status}`,
      }
      API.account
        .accountStatusChange(data.id, params)
        .then((res) => {
           this.$message({
              type: "success",
              message: "已停用!",
              })
                    this.query()
           })
        .catch((err) => {
          // 1. loading 結束
          this.isLoading = false
        })
    },

    //停用
    accountSpecialStatusProcessing(data, status) {
      // 1. 狀態轉換顯示文字
      let statusName = ""
       if (status == "DISABLED") statusName = "停用"

      // 2. 彈窗顯示及彈窗確認後操作
      this.$confirm(`確定此帳號要[${statusName}]嗎？`, "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setStatus(data, status)
        })
        .catch(() => {
          // 取消操作 功能
        })
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 16px;
  font-weight: bold;
}

.el-form .el-select {
  width: 95% !important;
}
.form-range {
  display: inline-block;
}
.form-range.el-select {
  width: 30% !important;
}
.form-range-to {
  margin: 0 10px;
}
.btn-col {
  text-align: right;
}
.btn-col .el-button,
.btn-new .el-button {
  width: 96px;
}
.btn-new {
  float: right;
}

::v-deep .span-secondary {
  color: var(--secondary) !important;
}
::v-deep .span-success {
  color: var(--success) !important;
}
::v-deep .span-warning {
  color: var(--warning) !important;
}
::v-deep .span-danger {
  color: var(--danger) !important;
}

</style>
