<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card>
     
      <div slot="header">
        <span class="title">新增帳號</span>
      </div>
      
    <!--姓名-->
      <el-form ref="form" :rules="rules" :model="serviceAccount" label-width="100px" label-position="top">
         <el-form-item label="姓名" prop="name" class="fontDetail">
               <el-input
                v-model="serviceAccount.name"
                placeholder="請輸入姓名"
                style="width: 70%" 
                required              
              ></el-input>
          </el-form-item>

    <!--電子信箱-->
      <el-form-item label="電子信箱(新增帳號後無法再編輯)" prop="email" class="fontDetail">
               <el-input
                v-model="serviceAccount.email"
                placeholder="請輸入電子信箱"
                style="width: 70%" 
                required              
              >
              </el-input>
          </el-form-item>

    <!--密碼-->
       <el-form-item label="密碼" prop="password" class="fontDetail">
               <el-input
                v-model="serviceAccount.password"
                placeholder="請輸入密碼"
                style="width: 70%" 
                required              
              >
              
              </el-input>
          </el-form-item>

    <!--功能群組-->
        <el-form-item label="功能群組" class="fontDetail">
             <el-select
                v-model="serviceAccount.serviceGroupTeamIds"
                multiple
                placeholder="請選擇"
                style="width: 350px;"
                >
                <el-option
                    v-for="item in queryServiceGroupTeamList"
                    :key="item.serviceGroupTeamId"
                    :label="item.name"
                    :value="item.serviceGroupTeamId"
                >
                </el-option>
            </el-select>
          </el-form-item>
   

         <!--新增帳號＿底部按鈕-->
            <el-form-item>
            <div class="dialog-footer">
                <el-button type="primary" @click="add">新增</el-button>
            </div>
            </el-form-item>

      </el-form>

    </el-card>
  
  </div>
</template>

<script>
import API from "../../api"
import { Message } from "element-ui"
import Utils from "../../utils"


export default {
  name: "AccountAdd",
  props: {
    id: String,
    clickTime: Date,
  },
  
  data() {
     // 電子信箱驗證
      var checkEmailValidator = (rule, value, callback) => {
        if (!Utils.Check.checkEmail(value)) {
          return callback(new Error("信箱格式錯誤"));
        } else {
          return callback();
        }
      };
      //密碼格式檢查
      var checkPwordValidator = (rule, value, callback) => {
        if (!Utils.Check.checkPword(value)) {
          return callback(new Error("密碼格式錯誤"));
        } else {
          return callback();
        }
      };
     return {
        isLoading: false,
        serviceAccount: {
            name: null,
            email: null,
            password: null,
            serviceGroupTeamIds: [],
            
        },
        
        queryServiceGroupTeamList: [],
        rules: {
            name: [
                { required: true, message: "姓名名稱必填", trigger: "blur" },
                { min: 1, max: 30, message: "長度在1-30個字符", trigger: "blur" },
            ],
            email: [
                { required: true, message: "請輸入電子信箱帳號", trigger: "blur" },
                { validator: checkEmailValidator, trigger: "blur" }
                ],
            password: [
                { required: true, message: "必填", trigger: "blur" },
                { validator: checkPwordValidator, trigger: "blur" }
                ],
            
            },
       
        
     }
  },

   watch: {
    clickTime: function (newVal) {
        this.openWindow()
      },
     
    },
    created() {
        this.openWindow()
    },

    methods: {
        openWindow(){
            if (this.$refs["form"]) {
            this.$refs["form"].resetFields() //按新增鍵後, 新增頁面清空
          }
           
            Promise.all([
            this.getAllServiceGroupTeam(),
            
            ]).then((res) => {
            if (res[0] && res[1]) {
                //恢復預設值
                this.serviceAccount.name = null;
                this.serviceAccount.email = null;
                this.serviceAccount.password = null;
                this.serviceAccount.serviceGroupTeamIds = [];
            }
            });
            
          }, 

        //取得 全部群組功能 列表
            getAllServiceGroupTeam() {
                return new Promise((resolve, reject) => {
                this.isLoading = true;
                API.groupTeam
                    .getAllServiceGroupTeam()
                    .then((res) => {
                    // 結果
                    this.queryServiceGroupTeamList = res.data;

                    this.isLoading = false;
                    resolve(true);
                    })
                    .catch((e) => {
                    console.log(`getAllServiceGroupTeam getList error`);
                    Message({
                        message: "取值失敗!",
                        type: "error",
                        duration: 3 * 1000,
                    });
                    this.isLoading = false;
                    reject(false);
                    });
                });
            },

   
        //按鈕: 新增
        add() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            //檢查 功能群組
            if (this.serviceAccount.serviceGroupTeamIds.length == 0) {
              Message({
                message: "請設定功能群組!",
                type: "warning",
              });
              return;
            }
            //新增帳號
            this.isLoading = true;
            API.account
              .addAccount(this.serviceAccount)
              .then((res) => {
                Message({
                    message: "新增成功!",
                    type: "success",
                  });
                  this.isLoading = false;
                  this.$emit("add-finish")
                  this.$refs["form"].resetFields() //設定新增完後, 此新增頁面清除
                }) 
              .catch((err) => {
                // 1. loading 結束
                this.isLoading = false;
              });
          } else {
            Message({
              message: "格式有誤，請重新檢查!",
              type: "warning",
            });
          }
        });
      },
      
      

    }

}
</script>

<style scoped>
/*title*/
.title {
  font-size: 18px;
  font-weight: bold;
}

/*label*/
.fontDetail {
  margin-left: 35px;
  font-weight: 520;
  font-size: 10px;
  color: #585858;
  margin-bottom: 10px;
}


/*頁面最底按鈕位置*/
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

::v-deep .el-form-item__label {
  margin-bottom: 0px;
  padding: 0px;
  }


</style>