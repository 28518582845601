<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card>
     
      <div slot="header">
        <span class="title">編輯帳號</span>
      </div>
      
    <!--姓名-->
      <el-form ref="editForm" :rules="rules" :model="serviceAccount" label-width="100px" label-position="top">
         <el-form-item label="姓名" prop="name" class="fontDetail">
               <el-input
                v-model="serviceAccount.name"
                placeholder="請輸入姓名"
                style="width: 70%" 
                required              
              ></el-input>
          </el-form-item>

    <!--電子信箱-->
      <el-form-item label="電子信箱(新增帳號後無法再編輯)" prop="email" class="fontDetail">
               <el-input
                v-model="serviceAccount.email"
                placeholder="請輸入電子信箱"
                style="width: 70%" 
                required    
                disabled          
              >
              </el-input>
          </el-form-item>

    <!--密碼-->
       <el-form-item prop="password" class="fontDetail">
              <span slot="label">
                密碼
                  <el-tooltip effect="dark" content="如果留白不更新" placement="right">
                    <span  class="notice">?</span>
                  </el-tooltip>
              </span>
                <el-input
                  v-model="serviceAccount.password"
                  placeholder="請輸入密碼"
                  style="width: 70%" 
                  required              
                >
                
                </el-input>
          </el-form-item>

    <!--功能群組-->
        <el-form-item label="功能群組" class="fontDetail">
             <el-select
                v-model="serviceAccount.serviceGroupTeamIds"
                multiple
                placeholder="請選擇"
                style="width: 350px;"
                @change="changeGroupTeam()"
                >
                <el-option
                    v-for="item in queryServiceGroupTeamList"
                    :key="item.serviceGroupTeamId"
                    :label="item.name"
                    :value="item.serviceGroupTeamId"
                >
                </el-option>
            </el-select>
          </el-form-item>
   

         <!--新增帳號＿底部按鈕-->
            <el-form-item>
            <div class="dialog-footer">
                <el-button type="primary" @click="edit">儲存</el-button>
            </div>
            </el-form-item>

      </el-form>

    </el-card>
  
  </div>
</template>

<script>
import API from "../../api"
import { Message } from "element-ui"
import Utils from "../../utils"


export default {
  name: "AccountEdit",
  props: {
    id: String,
    clickTime: Date,
  },
  
  data() {
     //密碼格式檢查
      var checkPwordValidator = (rule, value, callback) => {
        if (!Utils.Check.checkPword(value)) {
          return callback(new Error("密碼格式錯誤"));
        } else {
          return callback();
        }
      };
     return {
        isLoading: false,
        serviceAccount: {
            name: null,
            email: null,
            password: null,
            serviceGroupTeamIds: [],
            
        },
        queryServiceGroupTeamList: [],
        rules: {
             name: [
                { required: true, message: "姓名名稱必填", trigger: "blur" },
                { min: 1, max: 30, message: "長度在1-30個字符", trigger: "blur" },
            ],
            email: [{ required: true, message: "請輸入電子信箱帳號", trigger: "blur" } ],
               
            password: [
                  { validator: checkPwordValidator, trigger: "blur" }
                ],
            
            },
       
        
     }
  },

   watch: {
    clickTime: function (newVal) {
        this.openWindow()
      },
     
    },
    created() {
        this.openWindow()
    },

    methods: {
        openWindow(){
            // 1.移除校验结果
           this.resetForm("editForm")
                      
            Promise.all([this.getAllServiceGroupTeam()]).then(
              (res) => {
              
                  this.getAccountData(this.id)
              
            }
          ) 
        }, 

        //取得 全部群組功能 列表
            getAllServiceGroupTeam() {
                return new Promise((resolve, reject) => {
                this.isLoading = true;
                API.groupTeam
                    .getAllServiceGroupTeam()
                    .then((res) => {
                    // 結果
                    this.queryServiceGroupTeamList = res.data;

                    this.isLoading = false;
                    resolve(true);
                    })
                    .catch((e) => {
                    console.log(`getAllServiceGroupTeam getList error`);
                    Message({
                        message: "取值失敗!",
                        type: "error",
                        duration: 3 * 1000,
                    });
                    this.isLoading = false;
                    reject(false);
                    });
                });
            },

             // 取得帳號數據
                getAccountData(id) {
                if (id == null) return
                this.isLoading = true
                API.account.queryById(id).then((res) => {
                    this.serviceAccount = res.data

                    // 預設值
                    this.serviceAccount.serviceGroupTeamIds = []
                   
                    // 設定 功能群組ids
                    this.serviceAccount.serviceGroupTeams.forEach((element) => {
                    this.serviceAccount.serviceGroupTeamIds.push(element.serviceGroupTeamId.toString())
                    })

                    this.isLoading = false
                })
                },

   
        //按鈕: 儲存
        edit() {
        this.$refs["editForm"].validate((valid) => {
        if (valid) {     
          // 1. 檢查 功能群組
          if (this.serviceAccount.serviceGroupTeamIds.length == 0) {
            Message({
              message: "請設定功能群組!",
              type: "warning",
            })
            return
          }

          // 2. 編輯帳號
          this.isLoading = true
          API.account
            .update(this.id, this.serviceAccount)
            .then((res) => {
              // 1. 顯示訊息
                Message({
                  message: "更新成功!",
                  type: "success",
                })
                this.isLoading = false
                this.$emit("update-finish", res.data.message)
              }) 
            .catch((err) => {
              // 1. loading 結束
              this.isLoading = false
            })
        } 
      })
    },
      resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
      })
    },
      
        /**
         * 解決element-ui的下拉框有值卻無法選中的情況，解決方式：運用 this.$forceUpdate()強制重新整理，頁面正常選值。
         * 參考資料：https://www.796t.com/article.php?id=152156
         */
        changeGroupTeam() {
         this.$forceUpdate()
        },
      
  
    }

}
</script>

<style scoped>
/*title*/
.title {
  font-size: 18px;
  font-weight: bold;
}

/*label*/
.fontDetail {
  margin-left: 35px;
  font-weight: 520;
  font-size: 10px;
  color: #585858;
  margin-bottom: 10px;
}


/*頁面最底按鈕位置*/
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

::v-deep .el-form-item__label {
  margin-bottom: 0px;
  padding: 0px;
  }

  .notice {
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  background-color: #3b3a3a;
  border-radius: 10px;
  }


</style>